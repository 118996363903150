.loading-indicator {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-indicator div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #6f6ee2;
  animation: loading-indicator 0.6s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading-indicator div:nth-child(1) {
  left: 8px;
  animation-delay: -0.12s;
}
.loading-indicator div:nth-child(2) {
  left: 32px;
  animation-delay: -0.06s;
}
.loading-indicator div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading-indicator {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
