.form {
  & .box-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  & .horizontal-box {
    display: flex;
    gap: 20px;
  }
  & .input-box {
    margin-bottom: 10px;
    input {
      margin-left: 5px;
      border: 1px solid #7b79fa;
      border-radius: 4px;
      padding: 5px;
    }
  }
  & .submit-btn {
    width: 100px;
    height: 40px;
    border: 1px solid #7b79fa;
    border-radius: 4px;
    background-color: #6f6ee2;
    color: white;
    font-size: 14px;
    font-weight: 600;

    margin-top: 15px;
  }
}
