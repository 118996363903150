table {
  width: 100%;
  font-size: 18px;
  border-collapse: separate;
  border-spacing: 0px;

  padding: 20px 0;
  border-radius: 10px;

  background: #fff;
  box-shadow: 0 5px 30px #cccccc55;

  & th,
  td {
    padding: 10px;

    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
      padding-right: 30px;
    }
  }

  & tr:first-child {
    th {
      text-align: left;
    }
  }

  & tr.message {
    &:hover {
      background: #cccccc55;
    }

    &.error {
      background: #ff4b4b;
    }

    & .phone {
    }

    & .actions {
      display: flex;
      justify-content: space-around;
      & .button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #333;
        color: white;
        border-radius: 10px;
        padding: 10px 20px;
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        & .label {
          font-size: 15px;
          margin-left: 15px;
        }
      }
    }
  }
}
.preview {
  border-radius: 10px;
  background: #6f6ee2;
  padding: 20px;
  .name {
    color: #ffffffaa;
    margin-bottom: 10px;
  }
  & .chatbox {
    background: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 300px;
    white-space: pre-wrap;
    & .chatbox-button {
      border-radius: 5px;
      margin: 10px auto;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-content: center;
      border: solid #ccc 1px;
      color: #6f6ee2;
      text-decoration: none;
    }
  }
}
