.area {
  margin-bottom: 30px;
  & * {
    background: white;
    border-radius: 10px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 5px 30px #cccccc55;
  }
}

.select-template {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: self-start;
  gap: 20px;
  & .preview {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);

    background: #6f6ee2;
    padding: 20px;
    .name {
      color: #ffffffaa;
      margin-bottom: 10px;
    }
    & .chatbox {
      background: white;
      border-radius: 10px;
      padding: 20px;
      max-width: 300px;
      white-space: pre-wrap;
      .chatbox-button {
        border-radius: 5px;
        margin: 10px auto;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-content: center;
        border: solid #ccc 1px;
        color: #6f6ee2;
        text-decoration: none;
      }
    }
  }
}
