.send-message-root {
  & .button-tabs {
    margin-bottom: 15px;
    & .button {
      text-decoration: none;
      color: #6f6ee2;
      font-size: 18px;
      font-weight: 600;
      padding: 15px;
      border: 1px solid #ececec;
      border-radius: 4px;
    }

    & .clicked {
      background-color: #6f6ee2;
      color: #fff;
    }
  }
  & .form-wrapper {
    padding: 20px;
    border: 1px solid #ececec;
  }
}
