.login-form {
  & .input-box {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 340px;
    & input {
      margin-left: 5px;
      border: 1px solid #7b79fa;
      border-radius: 4px;
      padding: 5px;
    }
  }
  & .submit-btn {
    width: 50px;
    height: 30px;
    border: 1px solid #7b79fa;
    border-radius: 4px;
    background-color: #6f6ee2;
    color: white;
    font-size: 14px;
    font-weight: 600;

    margin-left: 10px;
  }
}
