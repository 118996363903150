.t-root {
  --highlight: #000;
}
.item .selection {
  border: solid #ccc 3px;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 10px;
}
.item .selection.active {
  background: var(--highlight);
  border: solid var(--highlight) 3px;
}
.item .name {
  font-size: 15px;
}
.item:hover {
  cursor: pointer;
}
.item:hover .selection:not(.active) {
  background: rgba(var(--highlight), 0.5);
}

.vertical.limited.t-root {
  border: solid #ccc 1px;
  border-radius: 5px;
  overflow-y: scroll;
}
.vertical .item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  /* justify-content: center; */
}
.vertical .item:not(:last-child) {
  border-bottom: solid #eee 1px;
}

.horizontal .item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}

.horizontal.spaced.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.horizontal.spaced .item {
  flex: 1 0;
}
