.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  & .sidebar {
    flex: 0 0 300px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #ececec;
    .header {
      font-size: 30px;
      font-weight: 300;
      color: #555;
    }
    & .navigation {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      & .link {
        font-size: 25px;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #000;
        text-decoration: none;
        .label {
          font-weight: 700;
          transition: all 0.3s;
        }
        & :hover {
          .label {
            opacity: 0.5;
            transform: translateX(20px);
          }
        }
        & .badge {
          padding: 5px 10px;
          font-size: 15px;
          border-radius: 25%;
          background: #555;
          color: white;
        }
      }
    }
  }

  main {
    flex: 1;
    padding: 40px;
    overflow-y: scroll;
    background: #f5f5f5;
    & .loading-indicator-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & h1 {
      font-size: 50px;
      font-weight: 300;
    }
  }
}
