.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.modal {
  background: white;
  padding: 2vw;
  width: 95vw;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  z-index: 100;
}
